import "./Title.css";
import MainPhoto from "../../images/mainPhoto.jpg";
import Logo from '../../images/logo.png';


export default function Title() {
    return (
        <main className="main_title">
            <div className="title">
                <h1>
                КОНЦЕРТНЫЙ ТУР <span>AKMAL'</span> <img src={Logo} alt="logo" height={'40px'}/>
                    </h1>
                    <div>
                    <a href="#concerts" className="button_title">
                    СМОТРЕТЬ БИЛЕТЫ
                    </a>
                    </div>
            </div>
           <img src={MainPhoto} alt="main photo"/>
        </main>
    );
}